import React from 'react';
import { get, isEmpty } from 'lodash';
import { styled, css } from '@mui/material/styles';
import { Box, Divider, NoSsr, Stack, Typography } from '@mui/material';

import { dayjsWithTimezone } from '@/src/utils';
import { DEFAULT_TIMEZONE } from '@/src/constants';
import { useTranslation } from '@/src/i18n';
import PractitionerInfo from './PractitionerInfo';
import {
  getIsDelayed,
  getStringDelay,
  getTimeTillAppointment,
} from '@/components/utils/appointment';
import TextContained from '@/components/atoms/TextContained/TextContained';
import useResponsive from '@/src/hooks/useResponsive';
import { HydratedAppointment } from '@/src/hooks/appointments/useAppointments';
import ConsultationType from './ConsultationType';

interface Props {
  appointment: HydratedAppointment;
  countdown?: boolean;
  isAnonymous?: boolean;
}

const StyledStack = styled(Stack)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.primary};
    padding: ${theme.spacing(1.5)};
    justify-content: space-around;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid ${theme.palette.background.borderLight};
    }

    .time {
      flex: 0 0 max-content;

      .time-till-appointment,
      .hour {
        font-weight: 600;
      }

      ${theme.breakpoints.up('sm')} {
        .time-till-appointment {
          text-align: center;
        }

        .hour,
        .date {
          display: inline;
        }
      }
    }
  `,
);

const AppointmentInfoBar: React.FC<Props> = ({
  appointment,
  countdown = true,
  isAnonymous,
}) => {
  const { t } = useTranslation();
  const timezone = appointment?.cardHcd?.settings?.timeZone || DEFAULT_TIMEZONE;
  const dayjsTZ = dayjsWithTimezone(timezone);

  const { isMobile, isDesktop } = useResponsive();

  const timeTillAppointment = React.useMemo(
    () => getTimeTillAppointment(appointment, t, isDesktop),
    [isDesktop, appointment, t],
  );

  if (isEmpty(appointment)) return null;

  const delay = getStringDelay(appointment);
  const isDelayed = getIsDelayed(appointment);

  return (
    <NoSsr>
      <StyledStack
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
        direction="row"
        className="appointment-info-bar"
      >
        <PractitionerInfo appointment={appointment} isAnonymous={isAnonymous} />

        <div className="time percy-hidden">
          {!isDelayed && countdown && (
            <Typography
              className="time-till-appointment"
              data-testid="time-till-appointment"
            >
              {timeTillAppointment}
            </Typography>
          )}
          {isDelayed && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: isMobile ? 'flex-start' : 'center',
              }}
            >
              <TextContained color="warning">+{delay}</TextContained>
            </Box>
          )}
          <Typography variant="body2" className="date">
            {dayjsTZ(get(appointment, 'startDate')).format('ddd D MMMM')}

            {`${isDesktop ? t('at') : ''}`}
          </Typography>
          <Typography variant="body2" fontWeight="600" className="hour">
            {dayjsTZ(get(appointment, 'startDate')).format('HH:mm')}
          </Typography>
        </div>

        {isDesktop && !isAnonymous && (
          <Box maxWidth="40%">
            <ConsultationType appointment={appointment} />
          </Box>
        )}
      </StyledStack>
    </NoSsr>
  );
};

export default AppointmentInfoBar;
