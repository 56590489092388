import {
  Appointment,
  Patient,
  User,
} from '@maiia/model/generated/model/api-patient/api-patient';

export type AppointmentPatient = {
  selectedProfileIndex: number;
  patientFirstName: string;
  patientLastName: string;
};

const getAppointmentPatient = (
  currentUser?: User,
  appointment?: Appointment | { profileId: string },
): AppointmentPatient => {
  const ret: AppointmentPatient = {
    selectedProfileIndex: -1,
    patientFirstName: '',
    patientLastName: '',
  };
  if (!currentUser || !appointment) {
    return ret;
  }
  const patientProfiles =
    currentUser.userPatientInformation?.userPatientProfiles ?? [];

  const { profileId } = appointment;
  const selectedProfileIndex = patientProfiles.findIndex(
    ({ id }) => id === profileId,
  );

  ret.selectedProfileIndex = selectedProfileIndex;

  if ('id' in appointment && appointment.id) {
    ret.patientFirstName = appointment?.patient?.firstName ?? '';
    ret.patientLastName = appointment?.patient?.lastName ?? '';
  } else if (selectedProfileIndex > 0) {
    ret.patientFirstName =
      patientProfiles[selectedProfileIndex]?.firstName ?? '';
    ret.patientLastName = patientProfiles[selectedProfileIndex]?.lastName ?? '';
  }

  return ret;
};

export default getAppointmentPatient;

export const hasReferringPractitioner = profile =>
  Boolean(profile.referringPractitioner) &&
  Boolean(profile.referringPractitioner.cpsId);

export const getReferringPractitionerFullName = profile => {
  if (!profile.referringPractitioner) return '';
  const { firstName, lastName } = profile.referringPractitioner;

  return `${firstName} ${lastName}`;
};

/**
 *
 * @param patient
 * @param user
 * @returns the patient address or the user default profile address as fallback
 */
export const getPatientFullAddress = (patient?: Patient, user?: User) => {
  if (patient?.address?.fullAddress && patient?.address?.fullAddress !== '') {
    return patient.address?.fullAddress;
  }

  return (
    user?.userPatientInformation?.userPatientProfiles?.find(o => o.isDefault)
      ?.address?.fullAddress || ''
  );
};
