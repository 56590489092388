import React from 'react';
import { Box, Typography, Stack } from '@mui/material';

import { IconCenter, IconHome, IconVideoCamera } from '@maiia/design-system';
import { HydratedAppointment } from '@/src/hooks/appointments/useAppointments';
import { useTranslation } from '@/src/i18n';

import {
  DIRECT_TELECONSULTATION,
  PHYSICAL,
  TELECONSULTATION,
  HOME_VISIT,
} from '@/src/constants';

const ConsultationType = ({
  appointment,
}: {
  appointment: HydratedAppointment;
}) => {
  const { t } = useTranslation();

  let label: string | undefined = appointment.consultationReason?.name;
  let IconComponent: JSX.Element | undefined;

  if ([TELECONSULTATION, DIRECT_TELECONSULTATION].includes(appointment.type)) {
    if (appointment.tlcConsultationReasonLabel)
      label = appointment.tlcConsultationReasonLabel;
    IconComponent = <IconVideoCamera aria-label={t('tlc_accessibility')} />;
  }

  if (appointment.type === PHYSICAL) {
    IconComponent = <IconCenter aria-label={t('physical_appointment')} />;
  }

  if (appointment.type === HOME_VISIT) {
    IconComponent = <IconHome aria-label={t('home-visit')} />;
  }

  if (!label) {
    return null;
  }

  return (
    <Stack spacing={1} direction="row">
      <Box sx={{ flex: '0 0 max-content' }}>{IconComponent}</Box>
      <Typography data-testid="reason">{label}</Typography>
    </Stack>
  );
};

export default ConsultationType;
