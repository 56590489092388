import React, { FC } from 'react';
import { User } from '@maiia/model/generated/model/api-patient/api-patient';
import { useCurrentUser } from '@docavenue/components';
import { styled, css, useTheme } from '@mui/material/styles';
import { Avatar, Box, NoSsr, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import {
  getAvatar,
  getCardFullName,
  getAppointmentCardUrl,
  getSubstitute,
} from '@/components/utils/index';
import getAppointmentPatient from '@/components/utils/patient';
import { isOnlineTlc } from '@/src/utils';
import { useTranslation } from '@/src/i18n';
import FlagPractitioner from '@/components/molecules/FlagPractitioner/FlagPractitioner';
import FlagCenter from '@/components/molecules/FlagCenter/FlagCenter';
import Link from '@/components/atoms/Link/Link';
import { HydratedAppointment } from '@/src/hooks/appointments/useAppointments';
import ConsultationType from './ConsultationType';

const PractitionerNameTag = styled(Box)(
  ({ theme }) => css`
    display: flex;
    margin-right: auto;

    ${theme.breakpoints.up('sm')} {
      margin-right: initial;
      flex: 0 0 max-content;
      max-width: 40%;
    }
  `,
);

const AvatarWrapper = styled(Box)(
  ({ theme }) => css`
    cursor: pointer;
    position: relative;
    height: ${theme.spacing(5)};
    width: ${theme.spacing(5)};
    margin-right: ${theme.spacing(1.5)};

    .is-online-badge {
      position: absolute;
      top: 85.355%;
      left: 85.355%;
      transform: translate(-50%, -50%);
      height: ${theme.spacing(1.5)};
      width: ${theme.spacing(1.5)};
      border: 2px solid white;
      border-radius: 50%;
      background-color: ${theme.palette.success.main};
    }

    .patient-avatar {
      position: absolute;
      bottom: 0;

      .initial {
        font-size: ${theme.spacing(1.5)};
        height: ${theme.spacing(2)};
        width: ${theme.spacing(2)};
      }
    }
  `,
);

const InfoWrapper = styled(Box)(
  ({ theme }) => css`
    .practitioner-name {
      display: flex;
      font-size: ${theme.spacing(2)};
      font-weight: 600;
      color: ${theme.palette.text.primary};
      word-break: break-word;
      cursor: pointer;

      svg {
        margin-left: ${theme.spacing(1)};
        // Compensate for text height, align-items: center;
        // won't work if the name took more than one line
        margin-top: ${theme.spacing(0.5)};
        flex: 0 0 ${theme.spacing(2)};
      }
    }
  `,
);

type PractitionerInfoProps = {
  appointment: HydratedAppointment;
  isAnonymous?: boolean;
};

const PractitionerInfo: FC<PractitionerInfoProps> = ({
  appointment,
  isAnonymous,
}) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser<User>();

  const { isSubstitute, substitute } = getSubstitute(appointment);
  const fullName = getCardFullName(t, appointment?.cardHcd);
  const { patientFirstName, selectedProfileIndex } = React.useMemo(
    () => getAppointmentPatient(currentUser, appointment),
    [appointment, currentUser],
  );
  const practitionerAvatarSrc =
    getAvatar(appointment) || '/static/images/default_practitioner.svg';
  const isPractitionerOnline = isOnlineTlc(appointment?.cardHcd);
  const isPractitionerVisibleOnline =
    appointment?.cardHcd?.settings?.isVisibleOnline;

  const theme = useTheme();
  const tabletAndWider = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <PractitionerNameTag>
      <Link href={getAppointmentCardUrl(appointment)}>
        <AvatarWrapper>
          <Avatar
            alt={fullName}
            src={practitionerAvatarSrc}
            imgProps={{ 'aria-hidden': 'true' }}
          />

          <div
            className={clsx(
              'patient-avatar',
              `patient-avatar--color${selectedProfileIndex}`,
            )}
          >
            {patientFirstName && !isAnonymous && (
              <div className="initial" aria-hidden="true">
                {patientFirstName.charAt(0).toUpperCase()}
              </div>
            )}
          </div>

          {isPractitionerOnline && <div className="is-online-badge" />}
        </AvatarWrapper>
      </Link>

      <InfoWrapper>
        <Link href={getAppointmentCardUrl(appointment)}>
          <Typography className="practitioner-name">
            {isSubstitute
              ? `${substitute.firstName} ${substitute.lastName}`
              : fullName}

            {!isSubstitute &&
              !isAnonymous &&
              isPractitionerVisibleOnline &&
              appointment?.profileId &&
              appointment.cardHcd?.professional?.practitionerId && (
                <FlagPractitioner
                  profileId={appointment?.profileId}
                  practitionerId={
                    appointment.cardHcd?.professional?.practitionerId
                  }
                />
              )}
            {!isPractitionerVisibleOnline && !isAnonymous && (
              <FlagCenter
                profileId={appointment?.profileId}
                centerId={appointment?.cardHcd?.center?.id}
              />
            )}
          </Typography>
        </Link>

        <NoSsr>
          {(tabletAndWider || isAnonymous) && (
            <Typography>
              {isPractitionerVisibleOnline
                ? appointment?.cardHcd?.professional?.speciality?.name
                : appointment?.cardHcd?.center?.speciality?.name}
            </Typography>
          )}
        </NoSsr>

        {isSubstitute && (
          <Typography color="text.medium">
            {`${t('substitute-of')} ${fullName}`}
          </Typography>
        )}

        <NoSsr>
          {!tabletAndWider && !isAnonymous && (
            <ConsultationType appointment={appointment} />
          )}
        </NoSsr>
      </InfoWrapper>
    </PractitionerNameTag>
  );
};

export default PractitionerInfo;
